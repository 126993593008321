import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import type { PageProps } from 'gatsby'
import {
  formatSearchState,
  parseSearchState,
  SearchProvider,
} from '@faststore/sdk'
import { useSession } from '@plurix/ecom-pages/src/sdk/session'
import type { SearchState } from '@faststore/sdk'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { ITEMS_PER_PAGE } from '@plurix/ecom-pages/src/constants'
import { applySearchState } from '@plurix/ecom-pages/src/sdk/search/state'
import { mark } from '@plurix/ecom-pages/src/sdk/tests/mark'
import { NpmProductListingPage } from 'src/local_modules'
import { replaceStringBreadcrumb } from '@plurix/ecom-pages/src/utils/replaceStrings'
import type { CollectionsApiResponse } from '@plurix/ecom-pages/src/types/Cluster'
import type {
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
} from '@generated/graphql'
import { storeUrl } from '../../../store.config'
import axios from 'axios'

type Props = PageProps<
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
  unknown,
  any
>

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
    serverData,
  } = props

  const selectedFacets = [
    { key: 'productClusterIds', value: serverData?.cluster?.id },
  ]

  const hrefState = useMemo(() => {
    const newState = parseSearchState(
      new URL(href ?? pathname, 'http://localhost')
    )

    // In case we are in an incomplete url
    if (newState.selectedFacets.length === 0) {
      newState.selectedFacets = selectedFacets ?? []
    }

    return formatSearchState(newState).href
  }, [href, pathname, selectedFacets])

  return useMemo(() => parseSearchState(new URL(hrefState)), [hrefState])
}

function Page(props: Props) {
  const {
    data: { site },
    location: { host, search },
    serverData,
  } = props
  const { locale } = useSession()
  const searchParams = useSearchParams(props)

  // No data was found
  if (serverData === null) {
    return null
  }

  const cluster = serverData?.cluster
  const slug = serverData?.slug
  const { page } = searchParams
  const title: string = cluster?.name ?? site?.siteMetadata?.title ?? ''
  const pageQuery = page !== 0 ? `?page=${page}` : ''
  const canonical =
    host !== undefined
      ? `https://${host}/${slug}${pageQuery}`
      : `/${slug}${pageQuery}`

  const itemListElements = [{ item: '/', name: title, position: 1 }]

  return (
    <SearchProvider
      onChange={(url) => applySearchState(url, search)}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd itemListElements={itemListElements} />
      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}
      <NpmProductListingPage
        title={title}
        itemListElement={itemListElements}
        slug={slug}
      />
    </SearchProvider>
  )
}

// This query is run during SSG
export const querySSG = graphql`
  query ClusterPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  try {
    const { data }: { data: CollectionsApiResponse } = await axios.post(
      `${storeUrl}/api/collections`
    )

    const cluster = data?.data?.collections?.items?.filter(
      (item) =>
        item.status === 'active' && replaceStringBreadcrumb(item.name) === slug
    )?.[0]

    // not found
    if (!cluster) {
      return {
        status: 404,
        props: null,
        headers: {
          location: `/404/?${slug}`,
        },
      }
    }

    return {
      props: { cluster, slug },
    }
  } catch (error) {
    // api error
    return {
      status: 400,
      props: null,
      headers: {
        location: `/404/?${slug}`,
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)
